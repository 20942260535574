import { Grid, Link, List, ListItem, Typography } from "@mui/material";
import Logo from "./Logo";
import MainSection from "./MainSection";
import ParentGrid from "./ParentGrid";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";

function Footer(props) {
    return (
        <div>
            <MainSection backgroundColor="#000">
                <ParentGrid>
                    <Grid item xs={12} md={4}>
                        <NavLink to="/">
                            <Logo color="white" width="170px"/>
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List sx={{paddingTop:'0'}}>
                            <ListItem sx={{paddingTop:'0'}}>
                                <Link 
                                    href="mailto:info@clinchouse.com"
                                    underline="always"
                                    sx={{display:'flex', alignItems:'center'}}
                                >
                                    <EmailOutlinedIcon fontSize="large" />
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        color="white"
                                        sx={{ textAlign: 'center' }}
                                        ml={1}
                                    >
                                        info@clinchouse.com
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem>
                                <Link 
                                    href="https://www.google.com/maps/place/Av.+Ra%C3%BAl+Scalabrini+Ort%C3%ADz+1147,+C1414DNL+CABA/@-34.5942263,-58.4306293,19z/data=!3m1!4b1!4m5!3m4!1s0x95bcca7770902ff1:0x911bbed677778417!8m2!3d-34.5942274!4d-58.4295081"
                                    target="_blank"
                                    underline="none"
                                    sx={{display:'flex', alignItems:'center'}}
                                >
                                    <LocationOnOutlinedIcon fontSize="large" />
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        color="white"
                                        sx={{ textAlign: 'center' }}
                                        ml={1}
                                    >
                                        Av. Raúl Scalabrini Ortíz 1147, Bs. As
                                    </Typography>
                                </Link>
                            </ListItem>
                        </List>
                        
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <List sx={{paddingTop:'0'}}>
                            <ListItem sx={{paddingTop:'0'}}>
                                <Link 
                                    href="https://api.whatsapp.com/send/?phone=5491140358561"
                                    target="_blank"
                                    underline="none"
                                    sx={{display:'flex', alignItems:'center'}}
                                >
                                    <WhatsAppIcon fontSize="large" />
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        color="white"
                                        sx={{ textAlign: 'center' }}
                                        ml={1}
                                    >
                                        Whatsapp
                                    </Typography>
                                </Link>
                            </ListItem>
                            <ListItem sx={{paddingTop:'0'}}>
                                <Link 
                                    href="https://www.instagram.com/clinchhouse/"
                                    target="_blank"
                                    underline="none"
                                    sx={{display:'flex', alignItems:'center'}}
                                >
                                    <InstagramIcon fontSize="large" />
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        color="white"
                                        sx={{ textAlign: 'center' }}
                                        ml={1}
                                    >
                                        Instagram
                                    </Typography>
                                </Link>
                            </ListItem>
                        </List>
                    </Grid>
                </ParentGrid>
            </MainSection>
            <MainSection pt="25px" pb="25px">
                <Stack>
                    <Typography
                        variant="subtitle2"
                        component="span"
                        color="black"
                        sx={{ textAlign: 'center' }}
                        ml={1}
                    >
                        Clinch House Copyright 2022. Todos los derechos reservados.
                    </Typography>
                </Stack>
            </MainSection>
        </div>
    );
  }
  
  export default Footer;