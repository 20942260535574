import InternalHero from '../sections/InternalHero';
import ClaseDePrueba from '../sections/ClaseDePrueba';
import { Box, Stack } from '@mui/system';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import HorariosImg from '../img/horarios.jpg'
import OrangeBg from '../img/backgrounds/orange_white.jpg'

function Horarios() {
  return (
    <div>
        <InternalHero text="HORARIOS"/>
        <MainSection backgroundImage={OrangeBg} pb="150px">
          <ParentGrid>
              <Stack direction="column" alignItems="center" maxWidth={'100%'} width="800px" >
                  <Box
                      component="img"
                      sx={{
                          maxWidth: '100%',
                      }}
                      alt='Grilla horarios'
                      src={ HorariosImg }
                  />
              </Stack>
          </ParentGrid>
      </MainSection>
        <ClaseDePrueba />
    </div >
  );
}

export default Horarios;