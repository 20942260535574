import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MainSection from '../../common/MainSection';
import ParentGrid from '../../common/ParentGrid';
import DisciplinasBoxes from '../../common/DisciplinasBoxes';
import OrangeBg from '../../img/backgrounds/orange_white.jpg'
import { List, ListItem } from '@mui/material';

function Crosstraining() {
  return (
    <div>
        <MainSection backgroundImage={OrangeBg}>
            <ParentGrid>
                <Stack width="100%" spacing={5} justifyContent="center" alignItems="center">
                    <Stack maxWidth={'100%'} width="600px" spacing={2} justifyContent='center' pb={5}>
                        <Typography
                            variant="h2"
                            component="h1"
                            color="black"
                            sx={{ textAlign: 'center' }}
                        >
                        CROSSTRAINING
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="white"
                            sx={{ textAlign: 'center' }}
                        >
                            CLASES PARA TODOS LOS NIVELES
                        </Typography>
                        <Typography
                            variant="p"
                            component="p"
                            color="third"
                            sx={{ textAlign: 'center' }}
                        >
                            La propuesta son clases divertidas donde nadie se sienta inhibido ni obligado a exigirse más de la cuenta.
                        </Typography>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection>
            <ParentGrid>
                <Stack direction={{xs:'column', md:'row'}} width="100%" spacing={{xs:'70px', md:'100px'}} justifyContent="center">
                    <Stack width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            INFORMACIÓN
                        </Typography>
                        <List className="disciplineList" >
                            <ListItem>
                              Clases para todos los niveles. La propuesta son clases divertidas donde nadie se sienta inhibido ni obligado 
                              a exigirse más de la cuenta.
                            </ListItem>
                            <ListItem>
                              Puede entrenar un principiante y un avanzado en la misma clase.
                            </ListItem>
                            <ListItem>
                              Nuestros salones de CrossTraining cuentan con un equipamiento moderno, 
                              amplios salones y un programa de capacitación adecuado a cualquier nivel de condición física.
                            </ListItem>
                            <ListItem>
                              Tenemos una comunidad de socios de todos los niveles y un equipo de entrenadores capacitados que enseñan el movimiento y la técnica varias veces al día.
                            </ListItem>
                            <ListItem>
                              Nos aseguramos de que cada sesión lleve al socio a estar más sano, más fuerte, más rápido y más feliz.
                            </ListItem>
                        </List>
                    </Stack>
                    <Stack  width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            BENEFICIOS
                        </Typography>
                        <List className="disciplineList">
                            <ListItem>
                              MEJORA LA FUERZA MUSCULAR.
                            </ListItem>
                            <ListItem>
                              MEJORA LA RESISTENCIA.
                            </ListItem>
                            <ListItem>
                              AYUDA A LA PÉRDIDA DE GRASA.
                            </ListItem>
                            <ListItem>
                              AYUDA A TONIFICAR LOS MÚSCULOS.
                            </ListItem>
                            <ListItem>
                              TRABAJAS TODOS LOS GRUPOS MUSCULARES
                            </ListItem>
                            <ListItem>
                              NO ES RUTINARIO
                            </ListItem>
                        </List>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection pt="0">
            <ParentGrid>
                <DisciplinasBoxes />
            </ParentGrid>
        </MainSection>
    </div>
  );
}

export default Crosstraining;