import Stack from "@mui/material/Stack";
import ContentBox from "./ContentBox";
import ImgCalistenics from "../img/calistenics.png";
import ImgFuncional from "../img/funcional.png";
import ImgBoxeo from "../img/glove.png";
import ImgBjj from "../img/bjj.png";
import ImgCrosstraining from "../img/cross.png";
import ImgThai from "../img/thai.png";
import { NavLink } from "react-router-dom";

const disciplinasNavItems = [
	{ name: "BOXEO", link: "/disciplinas/boxeo", img: ImgBoxeo },
	{ name: "FUNCIONAL", link: "/disciplinas/funcional", img: ImgFuncional },
	{ name: "BJJ", link: "/disciplinas/bjj", img: ImgBjj },
	{
		name: "CROSSTRAINING",
		link: "/disciplinas/crosstraining",
		img: ImgCrosstraining,
	},
	{ name: "CALISTENIA", link: "/disciplinas/calistenia", img: ImgCalistenics },
	//{name:'KICK/THAI', link:'/disciplinas/kick_thai', img: ImgThai},
];
function DisciplinasBoxes(props) {
	return (
		<Stack
			maxWidth="100%"
			width="100%"
			direction="row"
			justifyContent="center"
			sx={{ flexWrap: "wrap", rowGap: "10px", columnGap: "10px" }}
		>
			{disciplinasNavItems.map((item) => (
				<NavLink
					to={item.link}
					key={`${item.name}_mobile_menu_link`}
					className="navLink"
				>
					<ContentBox img={item.img}>{item.name}</ContentBox>
				</NavLink>
			))}
		</Stack>
	);
}

export default DisciplinasBoxes;
