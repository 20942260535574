import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import Logo from '../common/Logo';
import Btn from '../common/Button';
import HeroBg from '../img/backgrounds/hero.jpg'
import { NavLink } from 'react-router-dom';

function HomeHero() {
  return (
        <MainSection isHero="true" backgroundImage={HeroBg}>
            <ParentGrid pt="100px" pb="100px">
                <Stack maxWidth="100%" alignItems="center" spacing={7}>
                    <Box
                        component="div"
                        width="400px"
                        maxWidth="100%"
                    >
                        <Typography
                            variant="h5"
                            component="h1"
                            color="orange"
                            sx={{ textAlign: 'center' }}
                        >
                            CONTAMOS CON MÚLTIPLES DISCIPLINAS ADAPTADAS PARA MULTIPLES NIVELES.
                        </Typography>
                    </Box>
                    <Logo color="white" width={438}/>
                    <Box
                        component="div"
                        width="700px"
                        maxWidth="100%"
                    >
                        <Typography
                            variant="p"
                            component="p"
                            color="white"
                            sx={{ textAlign: 'center' }}
                        >
                            Las clases son dinámicas, divertidas  y siempre se hace deporte de la mano de coaches que cuidan que todos 
                            los ejercicios se hagan bien. Se arman grupos y amistades que hacen que ir al gimnasio sea un momento esperado del día
                        </Typography>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent='center'>
                        <NavLink to="/disciplinas">
                            <Btn padding="19px 40px">Disciplinas</Btn>
                        </NavLink>
                        <NavLink to="/clase_de_prueba">
                            <Btn variant="outlined" fontColor="isWhite" padding="18px 20px">Clase de prueba</Btn>
                        </NavLink>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
  );
}

export default HomeHero;