import MainSection from './MainSection';
import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import ParentGrid from './ParentGrid';
import Btn from './Button';
import { NavLink } from 'react-router-dom';


function SectionWithImage(props) {
  return (
        <MainSection backgroundImage={props.backgroundImage} pb={props.pb} pt={props.pt}>
            <ParentGrid>
                <Stack direction={{xs:'column', md:'row'}} alignItems={{xs:'center'}} maxWidth={'100%'} width="100%" spacing={8} >
                    <Box
                        component="img"
                        sx={{
                            width: 350,
                            maxWidth: '90%',
                        }}
                        alt={props.alt}
                        src={ props.img }
                    />
                    <Stack maxWidth={'100%'} justifyContent="center" alignItems={{xs:'center', md:'flex-start'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            sx={{ textAlign: 'center' }}
                            color="primary"
                        >
                        {props.topText}
                        </Typography>
                        <Typography
                            variant="h2"
                            component="h2"
                            sx={{ textAlign: 'center' }}
                            color={props.titleColor}
                        >
                            {props.titleText}
                        </Typography>
                        <NavLink to={props.link}>
                            <Btn color="primary">{props.ctaText}</Btn>
                        </NavLink>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
  );
}

export default SectionWithImage;