import InternalHero from '../sections/InternalHero';
import ClaseDePrueba from '../sections/ClaseDePrueba';
import Horarios from '../sections/Horarios';
import Pase from '../sections/Pase';
import Disciplinas from '../sections/Disciplinas';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import OrangeBg from '../img/backgrounds/orange_white.jpg'
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

function QuienesSomos() {
  return (
    <div>
        <InternalHero text="QUIENES SOMOS"/>
        <MainSection backgroundImage={OrangeBg} pb="150px">
          <ParentGrid>
            <Stack maxWidth="100%" width="800px" spacing={4}>
              <Typography
                  variant="h3"
                  component="p"
                  color="black"
                  sx={{ textAlign: 'center' }}
                >
                    Contamos con múltiples disciplinas adaptadas para todos los niveles. 
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  color="third"
                  sx={{ textAlign: 'center' }}
                >
                    Las clases son dinámicas, divertidas y siempre se hace deporte de la mano de coaches 
                    que cuidan que todo ejercicio se haga bien. Se arman grupos y amistades que hacen que 
                    ir al gimnasio sea un momento esperado del día. 
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  color="third"
                  sx={{ textAlign: 'center' }}
                >
                    Los profesores de cada actividad acompañan siempre a cada persona durante las clases para que 
                    pueda lograr sus objetivos y también para controlar la técnica de cada ejercicio. Son profes abiertos 
                    dispuestos a escuchar las inquietudes de cada uno. Si un ejercicio en particular no podés hacerlo el profe 
                    te lo cambia por uno que se adapte a vos.
                </Typography>
            </Stack>
          </ParentGrid>
        </MainSection>
        <Disciplinas />
        <Pase />
        <ClaseDePrueba />
        <Horarios />
    </div >
  );
}

export default QuienesSomos;