import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainSection from "../../common/MainSection";
import ParentGrid from "../../common/ParentGrid";
import OrangeBg from "../../img/backgrounds/orange_white.jpg";
import ShopCardBoxes from "../../common/ShopCardBoxes";
import ImgCardLogo from "../../img/card_logo.jpg";

const planItems = [
	{
		title: "Open Box",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content:
			"Consiste en un entrenamiento libre. Se tiene acceso tanto al material como a las instalaciones del box para realizar ejercicio.",
		price: "29900",
		paymentMethods: ["efectivo", "mp"],
	},
	{
		title: "Open Box + Gym Libre",
		img: ImgCardLogo,
		link: "https://wa.me/541140358561",
		content:
			"Acceso para usar el box de 10hs a 17hs y realizar las actividades del gimnasio (con excepción de BJJ)",
		price: "45900",
		paymentMethods: ["efectivo", "mp"],
	},
];

function OpenBox() {
	return (
		<div>
			<MainSection backgroundImage={OrangeBg} pb="150px">
				<ParentGrid>
					<Stack
						width="900px"
						max-width="100%"
						spacing={5}
						justifyContent="center"
						alignItems="center"
					>
						<Typography
							variant="h3"
							component="h3"
							color="black"
							sx={{ textAlign: "center" }}
						>
							Tenemos planes adaptados a todas las necesidades:
						</Typography>
						<ShopCardBoxes plans={planItems} />
					</Stack>
				</ParentGrid>
			</MainSection>
		</div>
	);
}

export default OpenBox;
