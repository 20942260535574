import SectionWithImage from '../common/SectionWithImage';
import LogoSmall from '../img/logo_small.png';
import DotBg from '../img/backgrounds/dot_bg.jpg'

function ClaseDePrueba() {
  return (
    <SectionWithImage
        alt="Clinch logo reducido"
        img={LogoSmall}
        topText="ACCEDÉ A UNA"
        titleText="CLASE DE PRUEBA"
        titleColor="black"
        ctaText="Anotate"
        backgroundImage={DotBg}
        link="/clase_de_prueba"
        pb="100px"
        pt="100px"
    />
  );
}

export default ClaseDePrueba;