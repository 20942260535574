import * as React from 'react';
import ParentGrid from '../common/ParentGrid';
import { Alert, TextField, Typography } from '@mui/material';
import Btn from '../common/Button';
import { Stack } from '@mui/system';
import axios from 'axios';
import Logo from '../common/Logo';
import { NavLink } from 'react-router-dom';

function Login() {
  const [user, setUser] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [mailError, setMailError] = React.useState('');

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  function handleFormSubmit (e) {
    e.preventDefault();
    axios({
      method: 'POST',
      url: 'https://clinchouse.com/back/formHandlers/loginForm.php',
      headers: { 'content-type': 'application/json' },
      data: {user:user, password:password}
    })
      .then(result => {
        if(result.data === 'incorrect' || result.data === 'error'){
          setMailError('El usuario o la contraseña son incorrectos')
        }else if(result.data === 'correct'){
          window.location.href = '/back/admin/dashboard.php';
        }
      })
      .catch(error => console.log(error));
  };

  function handleMailError(){
    if(mailError !== ''){
      return(
        <Alert severity="error" sx={{fontSize:'15px'}}>{mailError}</Alert>
      );
    }
  }

  return (
    <div>
          <ParentGrid backgroundColor="#090909" width="100%" maxWidth="100%">
            <Stack maxWidth="90%" width="400px" minHeight="100vh" justifyContent="center" spacing={2}>
              <Logo width="400px" color="white"/>
              <Typography
                variant="h5"
                component="p"
                color="primary"
                sx={{ textAlign: 'center' }}
              >
                Introduce tu usuario y contraseña para entrar
              </Typography>
              {handleMailError()}
                <TextField sx={{backgroundColor:"#fff"}} id="user" label="Usuario" variant="filled" value={user}  onChange={handleUserChange}/>
                <TextField sx={{backgroundColor:"#fff"}} id="password" type="password" label="Contraseña" variant="filled" value={password} onChange={handlePasswordChange}/>
              <Btn onClick={handleFormSubmit}>Entrar</Btn>

              <Typography
                variant="p"
                component="p"
                color="#fff"
                sx={{ textAlign: 'center', paddingTop:'15px' }}
              >
                También puedes
              </Typography>
              <NavLink to="/" style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                    <Btn color="fourth" fontColor="isBlack">Volver a la web</Btn>
              </NavLink>
            </Stack>
          </ParentGrid>
    </div >
  );
}

export default Login;