import { Grid } from '@mui/material';

function ParentGrid(props) {
  return (
        <Grid container  
            justifyContent={props.align ? props.align : 'center'}
            sx={{
              margin: '0 auto', 
            }}
            pt={props.pt && props.pt}
            pb={props.pb && props.pb}
            backgroundColor={props.backgroundColor && props.backgroundColor}
            width={props.width ? props.width : '1200px'}
            maxWidth={props.maxWidth ? props.maxWidth : '90%'}
        >
            {props.children}
        </Grid>

  );
}

export default ParentGrid;