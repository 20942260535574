import SectionWithImage from '../common/SectionWithImage';
import RingBg from '../img/backgrounds/ring_bg.jpg'
import LogoWhite from '../img/logo_white.png';

function Horarios() {
  return (
    <SectionWithImage
      alt="Clinch logo alt"
      img={LogoWhite}
      topText="NUESTROS"
      titleText="HORARIOS"
      titleColor="white"
      ctaText="Ver más"
      backgroundImage={RingBg}
      link="/horarios"
      pb="200px"
      pt="200px"
    />
  );
}

export default Horarios;