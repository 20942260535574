import * as React from "react";
import InternalHero from "../sections/InternalHero";
import Horarios from "../sections/Horarios";
import Disciplinas from "../sections/Disciplinas";
import Pase from "../sections/Pase";
import MainSection from "../common/MainSection";
import ParentGrid from "../common/ParentGrid";
import {
	Alert,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import Btn from "../common/Button";
import axios from "axios";
import { Link } from "@mui/material";
import EmailSent from "../sections/EmailSent";

function ClaseDePrueba() {
	const [name, setName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [disciplina, setDisciplina] = React.useState("BJJ");
	const [prefHora, setPrefHora] = React.useState("mañana");
	const [emailSent, setMailSent] = React.useState("");
	const [mailError, setMailError] = React.useState("");

	const handleDisciplinaChange = (event) => {
		setDisciplina(event.target.value);
	};

	const handlePrefHoraChange = (event) => {
		setPrefHora(event.target.value);
	};

	const handleNameChange = (event) => {
		setName(event.target.value);
	};

	const handlePhoneNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	function handleFormSubmit(e) {
		e.preventDefault();
		axios({
			method: "POST",
			url: "https://clinchouse.com/back/formHandlers/paseForm.php",
			headers: { "content-type": "application/json" },
			data: {
				name: name,
				phoneNumber: phoneNumber,
				email: email,
				prefHora: prefHora,
				disciplina: disciplina,
			},
		})
			.then((result) => {
				if (result.data === "email sent") {
					setMailSent(true);
				} else if (result.data === "empty field") {
					setMailError(
						"Debes llenar todos los campos antes de enviar el formulario"
					);
				} else if (result.data === "email error") {
					setMailError(
						"Disculpa, el email ingresado no es valido, por favor introduce uno nuevo"
					);
				} else if (result.data === "email send failed") {
					setMailError(
						"Disculpa, no hemos podido enviar el formulario, por favor intenta de nuevo mas tarde"
					);
				}

				setTimeout(function () {
					setMailSent("");
					setMailError("");
				}, 5000);

				//console.log(result)
			})
			.catch((error) => console.log(error));
	}

	function handleMailError() {
		if (mailError !== "") {
			return (
				<Alert severity="error" sx={{ fontSize: "15px" }}>
					{mailError}
				</Alert>
			);
		}
	}
	return (
		<div>
			<InternalHero text="CLASE DE PRUEBA" />
			<MainSection>
				<ParentGrid>
					<Link
						href={"https://forms.gle/iLiLsBnZXDKqFp4R6"}
						target="_blank"
						underline="none"
					>
						<Btn>Quiero mi clase de prueba</Btn>
					</Link>
				</ParentGrid>
			</MainSection>
			<Pase />
			<Disciplinas />
			<Horarios />
		</div>
	);
	/*
  return (
    <div>
        <InternalHero text="CLASE DE PRUEBA"/>
        <EmailSent emailSent={emailSent} />
        <MainSection >
          <ParentGrid>
            <Stack maxWidth="100%" width="400px" spacing={2}>
              <Typography
                variant="h5"
                component="p"
                color="primary"
                sx={{ textAlign: 'center' }}
              >
                Envía el siguiente formulario para obtener tu clase de prueba.
              </Typography>
              {handleMailError()}
              <TextField id="name" label="Nombre" variant="filled" value={name}  onChange={handleNameChange}/>
              <TextField id="phoneNumber" label="Whatsapp" variant="filled" value={phoneNumber} onChange={handlePhoneNumberChange}/>
              <TextField id="email" label="E-mail" variant="filled" value={email} onChange={handleEmailChange}/>
              <FormControl variant="filled">
                <InputLabel id="disciplina_label" sx={{fontSize:'15px'}}>Elegí para que clase querés tu prueba:</InputLabel>
                <Select
                  labelId="disciplina"
                  id="disciplina"
                  value={disciplina}
                  onChange={handleDisciplinaChange}
                >
                  <MenuItem value="BJJ">BJJ</MenuItem>
                  <MenuItem value="Boxeo">Boxeo</MenuItem>
                  <MenuItem value="Funcional">Funcional</MenuItem>
                  <MenuItem value="CrossTraining">CrossTraining</MenuItem>
                  <MenuItem value="Calistenia">Calistenia</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="filled">
                <InputLabel id="disciplina_label" sx={{fontSize:'15px'}}>Preferencia horaria:</InputLabel>
                <Select
                  labelId="prefHora"
                  id="prefHora"
                  value={prefHora}
                  onChange={handlePrefHoraChange}
                >
                  <MenuItem value="mañana">Mañana</MenuItem>
                  <MenuItem value="tarde">Tarde</MenuItem>
                  <MenuItem value="noche">Noche</MenuItem>
                </Select>
              </FormControl>
              <Btn onClick={handleFormSubmit}>Quiero mi clase de prueba</Btn>
            </Stack>
          </ParentGrid>
        </MainSection>
        <Pase />
        <Disciplinas />
        <Horarios />
    </div >
  );
  */
}

export default ClaseDePrueba;
