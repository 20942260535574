import HomeHero from '../sections/HomeHero';
import Pase from '../sections/Pase';
import Disciplinas from '../sections/Disciplinas';
import ClaseDePrueba from '../sections/ClaseDePrueba';
import Horarios from '../sections/Horarios';

function Home() {
  return (
    <div>
        <HomeHero></HomeHero>
        <Pase pb="150px"></Pase>
        <Disciplinas />
        <ClaseDePrueba />
        <Horarios />

    </div >
  );
}

export default Home;