import Button from '@mui/material/Button';
import { NavLink } from "react-router-dom";
import { Stack } from '@mui/system';





function InternalNavbar(props) {
    return (
        <div>
            <Stack direction={{xs:'column', sm:'row'}} sx={{flexWrap:"wrap"}} justifyContent="center" alignItems="center">
                {props.navItems.map((item) => (
                <NavLink to={item.link} key={`${item.name}_menu_link`}>
                    <Button key={`${item.name}_menu_item`} sx={{ color: props.color ? props.color : '#fff', fontSize: '14px', fontWeight:'bold' }}>
                        {item.name}
                    </Button>
                </NavLink>
                ))}
            </Stack>
      </div>
    );
  }
  
  export default InternalNavbar;