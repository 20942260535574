import OrangeBg from '../img/backgrounds/orange_white.jpg'
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import InternalNavbar from '../appbar/InternalNavbar';
import { Link } from 'react-router-dom';
import Btn from '../common/Button';


const navItems = [
    {name:'MEMBRESIAS', link:'/quiero_ser_parte/membresias'}, 
    {name:'BJJ', link:'/quiero_ser_parte/bjj'}, 
    {name:'BJJ + GYM LIBRE', link:'/quiero_ser_parte/bjj_gym_libre'}, 
    {name:'PASE OPEN BOX', link:'/quiero_ser_parte/pase_open_box'}, 
  ];

function QuieroSerParte() {
  return (
    <div>
        <MainSection backgroundImage={OrangeBg} pb="150px">
            <ParentGrid>
            <Stack width="660px" maxWidth="100%" alignItems="center" spacing={2}>
                <Typography
                variant="h2"
                component="h2"
                color="black"
                sx={{ textAlign: 'center' }}
                >
                    ¡SACÁ TU PASE HOY!
                </Typography>
                <Typography
                variant="h5"
                component="h5"
                color="white"
                sx={{ textAlign: 'center' }}
                >
                    Congela tu cuota y Ganale a la inflación
                </Typography>
                <Typography
                variant="h5"
                component="h5"
                color="black"
                sx={{ textAlign: 'center' }}
                >
                    Contamos con múltiples disciplinas adaptadas para todos los niveles.
                    Vení a entrenar y a divertirte con nosotros.
                </Typography>
                <Link to="/quiero_ser_parte/membresias">
                    <Btn color="fourth" fontColor="isBlack">VER PASES</Btn>
                </Link>
            </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection>
            <ParentGrid>
                <Stack width="660px" maxWidth="100%" spacing={2}>
                    <Typography
                        variant="h3"
                        component="h3"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                    >
                        Tenemos planes adaptados a todas las necesidades
                    </Typography> 
                    <InternalNavbar navItems={navItems} color="black"/>
                </Stack>
            </ParentGrid>
        </MainSection>
    </div>
  );
}

export default QuieroSerParte;