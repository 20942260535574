import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import OrangeBg from '../img/backgrounds/orange_white.jpg'


function EmailSent(props) {

    function renderEmailSentMessage(){
        if(props.emailSent){
            return(
                <MainSection backgroundImage={OrangeBg} pb="150px">
                <ParentGrid>
                    <Stack maxWidth="100%" width="700px" spacing={2}>
                        <Typography
                            variant="h2"
                            component="h2"
                            color="black"
                            sx={{ textAlign: 'center' }}
                        >
                            GRACIAS POR COMUNICARTE CON NOSOTROS
                        </Typography>
                        <Typography
                            variant="p"
                            component="p"
                            color="black"
                            sx={{ textAlign: 'center' }}
                        >
                            Te estaremos contactando lo más pronto posible a través de los medios de contacto
                            que agregaste al formulario.
                        </Typography>
                    </Stack>
                </ParentGrid>
                </MainSection>
            )
        }
    }
  return (
    <div>
        {
            renderEmailSentMessage()
        }
    </div >
  );
}

export default EmailSent;