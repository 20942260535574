import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
      let scrollValue = getLocationValue(location.pathname);
      window.scrollTo(0, scrollValue);
      console.log(location)
    }, [location]);

    function getLocationValue(path){
      if(path.includes('disciplinas')){
        return 400;
      }
      return 0;
    }

    return <>{props.children}</>
  };
  
  export default ScrollToTop;