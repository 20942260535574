import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

function ContentBox(props) {
  return (
    <Box
        className="disciplineBox"
        sx={{
            width: {xs: 180, md: 180},
            height: {xs: 180, md: 220},
            backgroundColor: '#fff',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '&:hover': {
                backgroundColor: 'primary',
                opacity: [0.9, 0.8, 0.7],
                cursor: 'pointer',
            },
        }}
    >
        <Stack justifyContent='center' alignItems="center" spacing={{xs:1, md:2}}>
            <Box
                component="img"
                sx={{
                    width: 60,
                    maxWidth: '80%',
                }}
                alt={props.alt}
                src={props.img}
            />
            <Typography
                variant="h5"
                component="span"
                color="third"
                sx={{ textAlign: 'center' }}
            >
                {props.children}
            </Typography>
            <Typography
                variant="h5"
                component="span"
                color="primary"
                className="disciplineBoxLinkText"
                sx={{ fontSize:{xs:'12px', md:'18px' }, textAlign: 'center', textDecoration: 'underline'}}
            >
                {props.linkText ? 'props.linkText' : 'Ver más >'}
            </Typography>
        </Stack>
    </Box>
  );
}

export default ContentBox;