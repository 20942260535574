import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainSection from "../../common/MainSection";
import ParentGrid from "../../common/ParentGrid";
import DisciplinasBoxes from "../../common/DisciplinasBoxes";
import OrangeBg from "../../img/backgrounds/orange_white.jpg";
import { List, ListItem } from "@mui/material";

function Bjj() {
	return (
		<div>
			<MainSection backgroundImage={OrangeBg}>
				<ParentGrid>
					<Stack
						width="100%"
						spacing={5}
						justifyContent="center"
						alignItems="center"
					>
						<Stack
							maxWidth={"100%"}
							width="600px"
							spacing={2}
							justifyContent="center"
							pb={5}
						>
							<Typography
								variant="h2"
								component="h1"
								color="black"
								sx={{ textAlign: "center" }}
							>
								BJJ
							</Typography>
							<Typography
								variant="h5"
								component="h5"
								color="white"
								sx={{ textAlign: "center" }}
							>
								ENTRENÁ Y DIVERTITE EN NUESTRAS CLASES DE BJJ
							</Typography>
							<Typography
								variant="p"
								component="p"
								color="third"
								sx={{ textAlign: "center" }}
							>
								Vas a quemar grasas, tonificar tus músculos, hacer ejercicio
								cardiovascular y sobre todo pasar un buen rato.
							</Typography>
						</Stack>
					</Stack>
				</ParentGrid>
			</MainSection>
			<MainSection>
				<ParentGrid>
					<Stack
						direction={{ xs: "column", md: "row" }}
						width="100%"
						spacing={{ xs: "70px", md: "100px" }}
						justifyContent="center"
					>
						<Stack width={{ xs: "100%", md: "40%" }}>
							<Typography
								variant="h5"
								component="h5"
								color="primary"
								sx={{ textAlign: "center" }}
							>
								¿COMO FUNCIONA?
							</Typography>
							<List className="disciplineList">
								<ListItem>
									El Jiu-Jitsu (BJJ) es un arte marcial que se centra en la
									habilidad de controlar a un oponente que se resiste de manera
									que lo obligue a rendirse. Debido al hecho de que el control
									es generalmente más fácil en el suelo que en una posición de
									pie, gran parte de la técnica consta de la habilidad de llevar
									a un oponente al suelo y luchar por posiciones de control
									dominantes donde el oponente puede quedar inofensivo.
								</ListItem>
								<ListItem>
									En la primera mitad se hace ejercicio físico y cardio (ideal
									para cualquiera que quiera ponerse en forma) y la segunda
									mitad hay técnica con un compañero.
								</ListItem>
								<ListItem>Todas las clases son mixtas.</ListItem>
							</List>
						</Stack>
						<Stack width={{ xs: "100%", md: "40%" }}>
							<Typography
								variant="h5"
								component="h5"
								color="primary"
								sx={{ textAlign: "center" }}
							>
								BENEFICIOS
							</Typography>
							<List className="disciplineList">
								<ListItem>
									Te permite ponerte en forma. Es de los entrenamientos más
									completos para el cuerpo, y de los más efectivos a la hora de
									ganar masa muscular, no solo en la parte abdominal y superior,
									sino también en las piernas..
								</ListItem>
								<ListItem>
									Elimina el estrés, ya que liberas adrenalina y segregas
									serotonina que da sensación de bienestar.
								</ListItem>
								<ListItem>
									Cuando practicás bjj se pone en juego tu velocidad, tu
									técnica, tus músculos y tu potencia.
								</ListItem>
								<ListItem>
									Con el entrenamiento fisico estás liberando tensiones.
								</ListItem>
							</List>
						</Stack>
					</Stack>
				</ParentGrid>
			</MainSection>
			<MainSection pt="0">
				<ParentGrid>
					<DisciplinasBoxes />
				</ParentGrid>
			</MainSection>
		</div>
	);
}

export default Bjj;
