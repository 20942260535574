
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Appbar from "../appbar/Appbar";

function MainLayout(props) {
    return (
        <div>
            <Appbar />
            <Outlet />
            <Footer />
        </div>
    );
  }
  
  export default MainLayout;