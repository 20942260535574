import { Box } from "@mui/material";

function MainSection(props) {
  return (
    <Box  
      component="section"
      className={props.isHero ? 'App-hero-section' : 'App-section'}
      pt={props.pt && props.pt}
      pb={props.pb && props.pb}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundImage: props.backgroundImage && `url(${props.backgroundImage})`,
        backgroundSize: props.backgroundSize ? props.backgroundSize : 'cover',
        backgroundPosition: props.backgroundPosition ? props.backgroundPosition : 'center bottom',
        backgroundColor: props.backgroundColor ? props.backgroundColor : '#fff'
      }}
    >
        {props.children}
    </Box>
  );
}

export default MainSection;