import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MainSection from '../../common/MainSection';
import ParentGrid from '../../common/ParentGrid';
import DisciplinasBoxes from '../../common/DisciplinasBoxes';
import OrangeBg from '../../img/backgrounds/orange_white.jpg'
import { List, ListItem } from '@mui/material';

function Calistenia() {
  return (
    <div>
        <MainSection backgroundImage={OrangeBg}>
            <ParentGrid>
                <Stack width="100%" spacing={5} justifyContent="center" alignItems="center">
                    <Stack maxWidth={'100%'} width="600px" spacing={2} justifyContent='center' pb={5}>
                        <Typography
                            variant="h2"
                            component="h1"
                            color="black"
                            sx={{ textAlign: 'center' }}
                        >
                            CALISTENIA
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="white"
                            sx={{ textAlign: 'center' }}
                        >
                            ENTRENÁ Y DIVERTITE EN NUESTRAS CLASES DE CALISTENIA
                        </Typography>
                        <Typography
                            variant="p"
                            component="p"
                            color="third"
                            sx={{ textAlign: 'center' }}
                        >
                            Todo lo aprendido en esta clase vas a poder realizarlo en cualquier otra parte: 
                            una playa, tu casa, el parque, donde quieras.
                        </Typography>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection>
            <ParentGrid>
                <Stack direction={{xs:'column', md:'row'}} width="100%" spacing={{xs:'70px', md:'100px'}} justifyContent="center">
                    <Stack width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            ¿COMO FUNCIONA?
                        </Typography>
                        <List className="disciplineList" >
                            <ListItem>
                                Una actividad que busca trabajar solamente con el peso corporal para generar una 
                                mejor resistencia cardiovascular y más fuerza. ¡El mundo es tu gimnasio! ya que 
                                lo aprendido lo podés hacer en parques, escaleras, tu casa, la playa y más.
                            </ListItem>
                            <ListItem>
                                Se aprende sobre parada de manos, ejercicios colgado de las barras, pull ups, 
                                tabla con apoyo solo de manos, bandera humana (human flag) y todo lo que es 
                                aprender a utilizar tu propio cuerpo.
                            </ListItem>
                        </List>
                    </Stack>
                    <Stack  width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            BENEFICIOS
                        </Typography>
                        <List className="disciplineList">
                            <ListItem>
                                Fortalecimiento articular.
                            </ListItem>
                            <ListItem>
                                Conocimiento del cuerpo (propiocepción-percepción).
                            </ListItem>
                            <ListItem>
                                Mejorar resistencia cardiovascular.
                            </ListItem>
                            <ListItem>
                                Quema de grasa y tonificación corporal.
                            </ListItem>
                            <ListItem>
                                Recuperación de habilidades motoras básicas y generación de habilidades nuevas y avanzadas
                            </ListItem>
                        </List>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection pt="0">
            <ParentGrid>
                <DisciplinasBoxes />
            </ParentGrid>
        </MainSection>
    </div>
  );
}

export default Calistenia;