import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import DisciplinasBoxes from '../common/DisciplinasBoxes';

function Disciplinas(props) {
  return (
        <MainSection pb={props.pb} pt={props.pt}>
            <ParentGrid>
                <Stack maxWidth={'100%'} width="740px" spacing={2} justifyContent='center'>
                    <Typography
                        variant="h5"
                        component="h5"
                        color="primary"
                        sx={{ textAlign: 'center' }}
                    >
                    NUESTRAS
                    </Typography>
                    <Typography
                        variant="h2"
                        component="h2"
                        color="third"
                        sx={{ textAlign: 'center' }}
                    >
                        DISCIPLINAS
                    </Typography>
                    <Typography
                        variant="p"
                        component="p"
                        color="third"
                        sx={{ textAlign: 'center' }}
                    >
                        Tenemos varias disciplinas agrupadas en: BJJ, Boxeo, Funcional, CrossTraining, y Calistenia.
                        ¡Elegí la actividad que mejor se adapte a tus necesidades y empezá a entrenar sin más vueltas!
                    </Typography>
                    
                </Stack>
                <DisciplinasBoxes />
            </ParentGrid>
        </MainSection>
  );
}

export default Disciplinas;