import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../common/Logo';
import { NavLink } from "react-router-dom";


const drawerWidth = 240;
const navItems = [
        {name:'Home', link:'/'}, 
        {name:'Quienes somos', link:'/quienes_somos'}, 
        {name:'Horarios', link:'/horarios'}, 
        {name:'Disciplinas', link:'/disciplinas'}, 
        {name:'Quiero ser parte', link:'/quiero_ser_parte', className:'activeNavBtn', classNameMobile:'activeNavBtnMobile'}, 
        {name:'Clase de prueba', link:'/clase_de_prueba'}, 
        {name:'Contacto', link:'/contacto'}, 
    ];


function Appbar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} >
            <Typography variant="h6" sx={{ my: 2 }}>
            <NavLink to="/">
                <Logo width="90%"></Logo>
            </NavLink>
            </Typography>
            <Divider />
            <List>
            {navItems.map((item) => (
                <NavLink to={item.link} key={`${item.name}_mobile_menu_link`}>
                    <ListItem className="mobileMenuItem" key={`${item.name}_mobile_menu_item`} disablePadding>
                    <ListItemButton sx={{ textAlign: 'center', color:"#000"}} className={item.classNameMobile && item.classNameMobile}>
                        <ListItemText primary={item.name}/>
                    </ListItemButton>
                    </ListItem>
                </NavLink>
            ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div>
        <AppBar component="nav" color="transparent" sx={{backgroundColor:"rgba(0, 0, 0, 0.3);"}} elevation={0}>
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
            >
                <MenuIcon fontSize="large" sx={{color:"#fff"}}/>
            </IconButton>
            <Box
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' }, textAlign: 'left' }}
            >
                <NavLink to="/">
                    <Logo color="white" width="130px" />
                </NavLink>
            </Box>
                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {navItems.map((item) => (
                    <NavLink to={item.link} key={`${item.name}_menu_link`}>
                        <Button key={`${item.name}_menu_item`} sx={{ color: '#fff' }} className={item.className && item.className}>
                            {item.name}
                        </Button>
                    </NavLink>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
        <Box component="nav">
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            >
            {drawer}
            </Drawer>
        </Box>
      </div>
    );
  }
  
  export default Appbar;