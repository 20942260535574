import * as React from 'react';
import { Stack } from '@mui/system';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import Logo from '../common/Logo';
import HeroBg from '../img/backgrounds/hero.jpg'
import bjjBg from '../img/backgrounds/bjj_bg.jpg'
import bjj2Bg from '../img/backgrounds/bjj2_bg.jpg'
import calisteniaBg from '../img/backgrounds/calistenia_bg.jpg'
import calistenia2Bg from '../img/backgrounds/calistenia2_bg.jpg'
import crosstrainingBg from '../img/backgrounds/crosstraining_bg.jpg'
import QuienesSomosBg from '../img/backgrounds/quienes_somos_bg.jpg'
import boxBg from '../img/backgrounds/box_bg.jpg'
import box2Bg from '../img/backgrounds/box2_bg.jpg'
import ringMaingBg from '../img/backgrounds/ring_main_bg.jpg'
import { Typography } from '@mui/material';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function InternalHero(props) {
    const location = useLocation();
    const [bgImage, setBgImage] = React.useState(HeroBg);

    useEffect(() => {
        switch (location.pathname) {
            case '/disciplinas/boxeo':
                setBgImage(box2Bg)
                break;

            case '/disciplinas/bjj':
                setBgImage(bjjBg)
                break;
        
            case '/disciplinas/calistenia':
                setBgImage(calisteniaBg)
            break;

            case '/disciplinas/crosstraining':
                setBgImage(crosstrainingBg)
            break;

            case '/quiero_ser_parte':
                setBgImage(bjj2Bg)
            break;

            case '/horarios':
                setBgImage(boxBg)
            break;

            case '/clase_de_prueba':
                setBgImage(calistenia2Bg)
            break;

            case '/quienes_somos':
                setBgImage(QuienesSomosBg)
            break;

            case '/contacto':
                setBgImage(ringMaingBg)
            break;

            default:
                setBgImage(HeroBg)
                break;
        }
        
    }, [location]);

    function doThis() {
        if(props.text){
            return(
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{ textAlign: 'center' }}
                    color="primary"
                >
                    {props.text}
                </Typography>
            )
        }
    }
  return (
    <MainSection pt="150px" pb="150px" backgroundImage={bgImage}>
        <ParentGrid>
            <Stack maxWidth="100%" alignItems="center" spacing={4}>
                <Logo color="white" width={400}/>
                {doThis()}
                {props.children && props.children}
            </Stack>
        </ParentGrid>
    </MainSection>
  );
}

export default InternalHero;