import InternalHero from "../sections/InternalHero";
import ClaseDePrueba from "../sections/ClaseDePrueba";
import Horarios from "../sections/Horarios";
import Pase from "../sections/Pase";
import { Outlet } from "react-router-dom";
import InternalNavbar from "../appbar/InternalNavbar";

const navItems = [
	{ name: "BOXEO", link: "/disciplinas/boxeo" },
	{ name: "FUNCIONAL", link: "/disciplinas/funcional" },
	{ name: "BJJ", link: "/disciplinas/bjj" },
	{ name: "CROSSTRAINING", link: "/disciplinas/crosstraining" },
	{ name: "CALISTENIA", link: "/disciplinas/calistenia" },
	//{name:'KICK/THAI', link:'/disciplinas/kick_thai'},
];

function Disciplinas(props) {
	return (
		<div>
			<InternalHero text="DISCIPLINAS">
				<InternalNavbar navItems={navItems} />
			</InternalHero>
			<Outlet />
			<Pase />
			<ClaseDePrueba />
			<Horarios />
		</div>
	);
}

export default Disciplinas;
