import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';


const RegularButton = styled(Button)({
    borderRadius: '16px',
});

function Btn(props) {
  return (
    <RegularButton 
        variant={props.variant ? props.variant : 'contained'}
        color={props.color ? props.color : 'primary'}
        size={props.size ? props.size : 'large'}
        className={props.fontColor && props.fontColor}
        onClick={props.onClick && props.onClick}
        sx={{padding: props.padding ? props.padding : '12px 40px', fontSize:'14px', fontWeight:'bold'}}
    >
        {props.children}
    </RegularButton>
  );
}

export default Btn;