import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import MainSection from "../../common/MainSection";
import ParentGrid from "../../common/ParentGrid";
import DisciplinasBoxes from "../../common/DisciplinasBoxes";
import OrangeBg from "../../img/backgrounds/orange_white.jpg";
import { List, ListItem } from "@mui/material";

function Kick() {
	return (
		<div>
			<MainSection backgroundImage={OrangeBg}>
				<ParentGrid>
					<Stack
						width="100%"
						spacing={5}
						justifyContent="center"
						alignItems="center"
					>
						<Stack
							maxWidth={"100%"}
							width="600px"
							spacing={2}
							justifyContent="center"
							pb={5}
						>
							<Typography
								variant="h2"
								component="h1"
								color="black"
								sx={{ textAlign: "center" }}
							>
								KICKBOXING / THAI
							</Typography>
							<Typography
								variant="h5"
								component="h5"
								color="white"
								sx={{ textAlign: "center" }}
							>
								ESTA CLASE COMBINA DOS DISCIPLINAS: KICKBOXING Y <br></br> MUAY
								THAI.
							</Typography>
							<Typography
								variant="p"
								component="p"
								color="third"
								sx={{ textAlign: "center" }}
							>
								El Muay Thai es un arte marcial que utiliza prácticamente todas
								las extremidades y que puede ser desarrollado por medio de una
								serie de diferentes golpes que el deportista le propina a su
								contrincante. Es conocido como «el arte de las ocho
								extremidades». Esto se refiere a los 8 golpes de muay thai en
								donde se utilizan las extremidades, además de otros puntos de
								contactos incluidos los puños, los codos, las rodillas y las
								espinillas.
							</Typography>
							<Typography
								variant="p"
								component="p"
								color="third"
								sx={{ textAlign: "center" }}
							>
								El kickboxing es un grupo de deportes de combate de pie y una
								forma de boxeo, basados en patadas y golpes de puño.
							</Typography>
						</Stack>
					</Stack>
				</ParentGrid>
			</MainSection>
			<MainSection>
				<ParentGrid>
					<Stack
						direction={{ xs: "column", md: "row" }}
						width="100%"
						spacing={{ xs: "70px", md: "100px" }}
						justifyContent="center"
					>
						<Stack width={{ xs: "100%", md: "40%" }}>
							<Typography
								variant="h5"
								component="h5"
								color="primary"
								sx={{ textAlign: "center" }}
							>
								¿COMO FUNCIONA?
							</Typography>
							<List className="disciplineList">
								<ListItem>Todas las clases son mixtas.</ListItem>
							</List>
						</Stack>
						<Stack width={{ xs: "100%", md: "40%" }}>
							<Typography
								variant="h5"
								component="h5"
								color="primary"
								sx={{ textAlign: "center" }}
							>
								BENEFICIOS
							</Typography>
							<List className="disciplineList">
								<ListItem>Aumenta la fuerza y agilidad</ListItem>
								<ListItem>Aumenta la disciplina</ListItem>
								<ListItem>Mejora la agilidad mental</ListItem>
								<ListItem>Alivia el estrés</ListItem>
								<ListItem>Tonifica tu cuerpo</ListItem>
							</List>
						</Stack>
					</Stack>
				</ParentGrid>
			</MainSection>
			<MainSection pt="0">
				<ParentGrid>
					<DisciplinasBoxes />
				</ParentGrid>
			</MainSection>
		</div>
	);
}

export default Kick;
