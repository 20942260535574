import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Link } from '@mui/material';
import { Box, Stack } from '@mui/system';
import ImgMp from '../img/logo_mp.png'
import ImgCash from '../img/efectivo_icon.png'

function ShopCard(props) {
  function showPrice() {
    //if(!props.price) return '';
    return(
      <Stack direction="row" height="25px">
        <Typography variant="h5" color="text.secondary" sx={{marginRight:'5px'}}>
            {props.price ? '$'+props.price : ''} 
          </Typography>
          {props.paymentMethods.map((item, index) => (
             <Box
              component="img"
              sx={{
                  height: 25,
                  marginRight: '10px'
              }}
              alt={item === 'mp' ? 'mp' : 'efectivo'}
              src={item === 'mp' ? ImgMp : ImgCash}
              key={`item_payment_${index}${props.title.split(' ').join('_')}`}
          />
          ))}
      </Stack>
    )
  }

  return (
      <Card sx={{ maxWidth: '100%', width:"250px" }}>
        <Link href={props.link} target="_blank" underline="none" color="black" >
          <CardMedia
            component="img"
            alt={props.alt}
            height="140"
            image={props.img}
          />
          <CardContent >
            <Typography gutterBottom variant="h5" component="div">
              {props.title}
            </Typography>
            <Typography sx={{display:'block', minHeight:'100px'}} variant="p" color="text.secondary">
              {props.content}
            </Typography>
            {showPrice()}
          </CardContent>
          <CardActions>
              <Button 
                size="large" 
                endIcon={<StorefrontIcon />}
                sx={{fontSize:'13px', fontWeight:'bold'}}
              >
                Consultar Disponibilidad
              </Button>
          </CardActions>
        </Link>
      </Card>
  );
}

export default ShopCard;