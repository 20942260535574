import * as React from 'react';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  typography: {
    fontFamily: 'Gotham',
    fontSize: 10,
    h1:{
      fontSize: '3.9rem',
      fontWeight: '900'
    },
    h2:{
      fontWeight: '900',
      fontSize: '2.7rem'
    },
    h3:{
      fontWeight: '900',
    },
    h5:{
      fontSize: '1.1rem',
      fontWeight: 'bold'
    },
    p:{
      fontSize: '1.1rem'
    },
    subtitle2: {
      fontSize: '0.9rem'
    }
    
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#faa41b',
      contrastText: "#fff"
    },
    secondary: {
      main: '#11cb5f',
    },
    third: {
      main: '#000',
    },
    fourth: {
      main: '#fff',
    },
  },
});

theme = responsiveFontSizes(theme);

export default function Palette(props) {
  return (
    <ThemeProvider theme={theme}>
        {props.children}
    </ThemeProvider>
  );
}