import { BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './pages/Home'
import './App.css';
import Theme from './common/Theme'
import QuienesSomos from './pages/QuienesSomos';
import Horarios from "./pages/Horarios";
import QuieroSerParte from "./sections/QuieroSerParte";
import QuieroSerParteLayout from "./layouts/QuieroSerParteLayout";
import ClaseDePrueba from "./pages/ClaseDePrueba";
import Login from "./pages/Login";
import Contacto from "./pages/Contacto";
import DisciplinasLayout from "./layouts/DisciplinasLayout";
import Disciplinas from "./sections/Disciplinas";
import Boxeo from "./pages/disciplinas/Boxeo";
import Bjj from "./pages/disciplinas/Bjj";
import Calistenia from "./pages/disciplinas/Calistenia";
import Crosstraining from "./pages/disciplinas/Crosstraining";
import Funcional from "./pages/disciplinas/Funcional";
import Membresias from "./pages/quiero_ser_parte/Membresias";
import BjjGym from "./pages/quiero_ser_parte/BjjGym";
import OpenBox from "./pages/quiero_ser_parte/OpenBox";
import BjjShop from "./pages/quiero_ser_parte/Bjj";
import MainLayout from "./layouts/MainLayout";
import ScrollToTop from "./common/ScrollTopTop";
import Kick from "./pages/disciplinas/Kick";
import Dashboard from "./pages/Dashboard";

function App() {
  return (
      <BrowserRouter> 
        <Theme>
          <ScrollToTop>
            <div className="App">
              <Routes>
                <Route path="login" index element={<Login />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="/" element={<MainLayout />} >
                  <Route index element={<Home />} />
                  <Route path="quienes_somos" element={<QuienesSomos />} />
                  <Route path="horarios" element={<Horarios />} />
                  <Route path="quiero_ser_parte" element={<QuieroSerParteLayout />} >
                    <Route index element={<QuieroSerParte />} />
                    <Route path="membresias" element={<Membresias />} />
                    <Route path="bjj" element={<BjjShop />} />
                    <Route path="bjj_gym_libre" element={<BjjGym />} />
                    <Route path="pase_open_box" element={<OpenBox />} />
                  </Route>
                  <Route path="clase_de_prueba" element={<ClaseDePrueba />} />
                  <Route path="contacto" element={<Contacto />} />
                  <Route path="disciplinas" element={<DisciplinasLayout />}> 
                    <Route index element={<Disciplinas />} />
                    <Route path="boxeo" element={<Boxeo />} />
                    <Route path="kick_thai" element={<Kick />} />
                    <Route path="bjj" element={<Bjj />} />
                    <Route path="calistenia" element={<Calistenia />} />
                    <Route path="crosstraining" element={<Crosstraining />} />
                    <Route path="funcional" element={<Funcional />} />
                  </Route>
                </Route>
              </Routes>
            </div>
          </ScrollToTop>
        </Theme>
      </BrowserRouter>
  );
}

export default App;
