import Stack from '@mui/material/Stack';
import ShopCard from './ShopCard';


function ShopCardBoxes(props) {
  return (
        <Stack width="100%" direction="row" justifyContent="center" sx={{ flexWrap: 'wrap', rowGap:'30px', columnGap:'30px'}}>
            {props.plans.map((item) => (
                <ShopCard 
                    title={item.title} 
                    link={item.link} 
                    alt={item.alt}
                    img={item.img}
                    content={item.content}
                    price={item.price}
                    paymentMethods={item.paymentMethods}
                key={`shop_card_${item.title.split(' ').join('_')}`}
                    
                >
                </ShopCard>
            ))}
        </Stack>
  );
}

export default ShopCardBoxes;