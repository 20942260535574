import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ParentGrid from '../common/ParentGrid';
import MainSection from '../common/MainSection';
import Btn from '../common/Button';
import OrangeBg from '../img/backgrounds/orange_white.jpg'
import { NavLink } from 'react-router-dom';

function Pase(props) {
  return (
    <MainSection backgroundImage={OrangeBg} pb={props.pb ? props.pb : '150px'} pt={props.pt}>
        <ParentGrid >
            <Stack maxWidth={'100%'}  width="550px" spacing={2} alignItems="center" justifyContent='center'>
                <Typography
                    variant="h5"
                    component="h5"
                    color="white"
                    sx={{ textAlign: 'center' }}
                >
                   ¡SACA TU PASE HOY!
                </Typography>
                <Typography
                    variant="h1"
                    component="h3"
                    color="black"
                    sx={{ textAlign: 'center' }}
                >
                    QUIERO MI PASE
                </Typography>
                <Typography
                    variant="p"
                    component="p"
                    color="black"
                    sx={{ textAlign: 'center' }}
                >
                    Contamos con múltiples disciplinas adaptadas para todos los niveles.
                    Vení a entrenar y a divertirte con nosotros.
                    Tenemos planes adaptados a todas las necesidades:
                </Typography>
                <NavLink to="/quiero_ser_parte">
                    <Btn color="fourth" fontColor="isBlack">Quiero ser parte</Btn>
                </NavLink>
            </Stack>
        </ParentGrid>
    </MainSection>

  );
}

export default Pase;