import LogoImgWhite from '../img/logo_white.png'
import LogoImgBlack from '../img/logo.png'
import Box from '@mui/material/Box';

function Logo(props) {
  return (
    <Box
      component="img"
      sx={{
        maxWidth: '100%',
      }}
      width={props.width ? props.width : 350}
      alt="Clinch Logo"
      src={props.color === 'white' ? LogoImgWhite : LogoImgBlack }
    />
  );
}

export default Logo;