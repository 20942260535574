import * as React from 'react';
import InternalHero from '../sections/InternalHero';
import ClaseDePrueba from '../sections/ClaseDePrueba';
import Horarios from '../sections/Horarios';
import Pase from '../sections/Pase';
import MainSection from '../common/MainSection';
import ParentGrid from '../common/ParentGrid';
import { Alert, TextField, Typography } from '@mui/material';
import Btn from '../common/Button';
import { Stack } from '@mui/system';
import axios from 'axios';
import Map from '../sections/Map';
import EmailSent from '../sections/EmailSent';

function Contacto() {
  const [name, setName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [emailSent, setMailSent] = React.useState('');
  const [mailError, setMailError] = React.useState('');


  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function handleFormSubmit (e) {
    e.preventDefault();
    axios({
      method: 'POST',
      url: 'https://clinchouse.com/back/formHandlers/contactForm.php',
      headers: { 'content-type': 'application/json' },
      data: {name:name, phoneNumber:phoneNumber, email:email, message:message}
    })
      .then(result => {
        if(result.data === 'email sent'){
          setMailSent(true);
        }else if(result.data === 'empty field'){
          setMailError('Debes llenar todos los campos antes de enviar el formulario')
        }else if(result.data === 'email error'){
          setMailError('Disculpa, el email ingresado no es valido, por favor introduce uno nuevo')
        }else if(result.data === 'email send failed'){
          setMailError('Disculpa, no hemos podido enviar el formulario, por favor intenta de nuevo mas tarde')
        }
        setTimeout(function(){
          setMailSent('');
          setMailError('');
        },5000)
        //console.log(result)
      })
      .catch(error => console.log(error));
  };

  function handleMailError(){
    if(mailError !== ''){
      return(
        <Alert severity="error" sx={{fontSize:'15px'}}>{mailError}</Alert>
      );
    }
  }

  return (
    <div>
        <InternalHero text="CONTACTO"/>
        <EmailSent emailSent={emailSent} />
        <MainSection >
          <ParentGrid>
            <Stack maxWidth="100%" width="400px" spacing={2}>
              <Typography
                variant="h5"
                component="p"
                color="primary"
                sx={{ textAlign: 'center' }}
              >
                Dejanos tus dudas o preguntas en el siguiente formulario y responderemos lo mas pronto posible.
              </Typography>
              {handleMailError()}
              <TextField id="name" label="Nombre" variant="filled" value={name}  onChange={handleNameChange}/>
              <TextField id="phoneNumber" type="number" label="Whatsapp" variant="filled" value={phoneNumber} onChange={handlePhoneNumberChange}/>
              <TextField id="email" label="E-mail" type="email" variant="filled" value={email} onChange={handleEmailChange}/>
              <TextField
                id="message"
                label="Consulta"
                multiline
                rows={4}
                value={message} 
                onChange={handleMessageChange}
                variant="filled"
              />
              <Btn onClick={handleFormSubmit}>ENVIAR</Btn>
            </Stack>
          </ParentGrid>
        </MainSection>
        <Map />
        <Pase />
        <ClaseDePrueba />
        <Horarios />
    </div >
  );
}

export default Contacto;