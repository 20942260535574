import InternalHero from '../sections/InternalHero';
import ClaseDePrueba from '../sections/ClaseDePrueba';
import Horarios from '../sections/Horarios';
import { Outlet } from 'react-router-dom';
import InternalNavbar from '../appbar/InternalNavbar';


const navItems = [
    {name:'MEMBRESIAS', link:'/quiero_ser_parte/membresias'}, 
    {name:'BJJ', link:'/quiero_ser_parte/bjj'}, 
    {name:'BJJ + GYM LIBRE', link:'/quiero_ser_parte/bjj_gym_libre'}, 
    {name:'PASE OPEN BOX', link:'/quiero_ser_parte/pase_open_box'}, 
  ];

function QuieroSerParteLayout() {
  return (
    <div>
        <InternalHero text="QUIERO SER PARTE">
          <InternalNavbar navItems={navItems} />
        </InternalHero>
        <Outlet />
        <ClaseDePrueba />
        <Horarios />
    </div >
  );
}

export default QuieroSerParteLayout;