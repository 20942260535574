import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MainSection from '../../common/MainSection';
import ParentGrid from '../../common/ParentGrid';
import DisciplinasBoxes from '../../common/DisciplinasBoxes';
import OrangeBg from '../../img/backgrounds/orange_white.jpg'
import { List, ListItem } from '@mui/material';

function Funcional() {
  return (
    <div>
        <MainSection backgroundImage={OrangeBg}>
            <ParentGrid>
                <Stack width="100%" spacing={5} justifyContent="center" alignItems="center">
                    <Stack maxWidth={'100%'} width="600px" spacing={2} justifyContent='center' pb={5}>
                        <Typography
                            variant="h2"
                            component="h1"
                            color="black"
                            sx={{ textAlign: 'center' }}
                        >
                        FUNCIONAL
                        </Typography>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="white"
                            sx={{ textAlign: 'center' }}
                        >
                            ENTRENÁ Y DIVERTITE EN NUESTRAS CLASES DE FUNCIONAL
                        </Typography>
                        <Typography
                            variant="p"
                            component="p"
                            color="third"
                            sx={{ textAlign: 'center' }}
                        >
                            Vas a quemar grasas, tonificar tus músculos, hacer ejercicio cardiovascular y sobre todo pasar un buen rato.
                        </Typography>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection>
            <ParentGrid>
                <Stack direction={{xs:'column', md:'row'}} width="100%" spacing={{xs:'70px', md:'100px'}} justifyContent="center">
                    <Stack width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            ¿COMO FUNCIONA?
                        </Typography>
                        <List className="disciplineList" >
                            <ListItem>
                                Se sigue un círculo de ejercicios que aumenta la fuerza de los músculos y ayuda a mejorar el rendimiento 
                                de todo el cuerpo
                            </ListItem>
                            <ListItem>
                                Esta disciplina está diseñada para que dentro de un tiempo limitado, todo tu cuerpo pase por un régimen 
                                de entrenamiento, lo que te ayuda a construir todos los músculos. La mayoría de las veces, este entrenamiento 
                                requiere usar tu propio peso corporal, y es todo un desafío hacerlo de manera adecuada. Estos ejercicios son 
                                autocomplacientes, y por lo tanto solo se pueden hacer hasta que el cuerpo se dé por vencido, y por esta razón 
                                se reducen las posibilidades de lesiones.
                            </ListItem>
                            <ListItem>
                            Estas sesiones de entrenamiento funcional duran 1 hora, y en ese tiempo se sigue un círculo de ejercicios que 
                            aumenta la fuerza de los músculos y ayuda a mejorar el rendimiento de todo el cuerpo. Lo mejor de este tipo de 
                            entrenamiento es que se basa únicamente en el tiempo y no en el número de veces que se debe repetir un ejercicio.
                            </ListItem>
                        </List>
                    </Stack>
                    <Stack  width={{xs:'100%',md:'40%'}}>
                        <Typography
                            variant="h5"
                            component="h5"
                            color="primary"
                            sx={{ textAlign: 'center' }}
                        >
                            BENEFICIOS
                        </Typography>
                        <List className="disciplineList">
                            <ListItem>
                                Trabaja el cuerpo entero
                            </ListItem>
                            <ListItem>
                                Ayuda a construir los músculos que usa una persona en su vida diaria
                            </ListItem>
                            <ListItem>
                                Fortalece músculos
                            </ListItem>
                            <ListItem>
                                Mejora la Flexibilidad
                            </ListItem>
                            <ListItem>
                                Mejora la agilidad
                            </ListItem>
                            <ListItem>
                                Quema calorías
                            </ListItem>
                            <ListItem>
                                Elimina el estrés, ya que liberas adrenalina y segregas serotonina que da sensación de bienestar
                            </ListItem>
                        </List>
                    </Stack>
                </Stack>
            </ParentGrid>
        </MainSection>
        <MainSection pt="0">
            <ParentGrid>
                <DisciplinasBoxes />
            </ParentGrid>
        </MainSection>
    </div>
  );
}

export default Funcional;