import React from 'react'
import GoogleMapReact from 'google-map-react'
import PinDropIcon from '@mui/icons-material/PinDrop';

function Map(props) {
    const location = {
        address: 'Av. Raúl Scalabrini Ortíz 1147, C1414DNL CABA',
        lat: -34.5942274,
        lng: -58.4300566,
    }
    const zoomLevel = 16;

    return (
        <div style={{height: '400px', width: '100%'}}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyAbj6cWDnWWrc6whCozzjYmkSpsAtd4aX8' }}
                defaultCenter={location}
                defaultZoom={zoomLevel}
            >
                <PinDropIcon 
                    lat={location.lat}
                    lng={location.lng}
                    text="My Marker"
                    fontSize="large"
                    sx={{marginTop:'-19px', marginLeft:'22px'}}
                />
            </GoogleMapReact>
        </div>
    );
}

export default Map;